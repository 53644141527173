import { SignedOut, useAuth } from '@clerk/clerk-react';
import { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MinimalLayout: FC<{ children: ReactNode }> = (props) => {
  const { userId, isLoaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId && isLoaded) {
      navigate('/dashboard');
    }
  }, [userId, isLoaded, navigate]);
  return <SignedOut>{props.children}</SignedOut>;
};

export default MinimalLayout;
